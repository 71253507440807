<div class="container">
    <div class="logos-redes">

          <a href="https://www.facebook.com/vistaslargas/" target="_blank">
            <img src="../../../assets/img/SocialMedia/SOCIAL MEDIA ICONS-01.svg" alt="facebook_vistasLargas" />
          </a>

          <a href="https://www.instagram.com/vistaslargas/" target="_blank">
            <img src="../../../assets/img/SocialMedia/SOCIAL MEDIA ICONS-02.svg" alt="instagram_vistasLargas" />
        </a>

          <a href="https://pt.linkedin.com/in/vistas-largas-842aa5133" target="_blank">
            <img src="../../../assets/img/SocialMedia/SOCIAL MEDIA ICONS-03.svg" alt="linkdin_vistasLargas" />
        </a>

          <a href="https://www.behance.net/gerala104" target="_blank">
            <img src="../../../assets/img/SocialMedia/SOCIAL MEDIA ICONS-04.svg" alt="behance_vistasLargas" />
          </a>

          <a href="https://api.whatsapp.com/send?phone=+351912216062" target="_blank">
            <img src="../../../assets/img/SocialMedia/SOCIAL MEDIA ICONS-05.svg" alt="whatsapp" />
          </a>

          <a href="https://www.livroreclamacoes.pt/inicio" target="_blank">
            <img src="../../../assets/img/livro_reclamacao.png" alt="livro de reclamacao" />
          </a>


  </div>

<!-- <div class="logos-redes">

  <div class="livro">

  </div>

</div> -->


</div>
