      <nav #menu id="home" [class.sticky]="isSticky">
        <div class="logo">
          <a routerLink="">
            <img [src]="logoSrc()" alt="">
          </a>
        </div>

          <ul>
            <li><a routerLink="/portfolio" routerLinkActive="active">Portfólio</a></li>
            <li><a href="#form">Orçamentos</a></li>
            <!-- <li><a href="#equipa">A equipa</a></li> -->
            <li><a routerLink="/contatos" routerLinkActive="active">Contactos</a></li>
          </ul>

          <div class="hamburguer" #hamburguer (click)="checkIsClicked()">
            <div *appLines="3" #line [ngClass]="{'border-lines': isSticky, 'line': !isSticky}"></div>

          </div>

          <div class="menu2" #menu2 [class.block]="isClicked">
            <a routerLink="/portfolio">Portfólio</a>
            <a href="#form">Orçamentos</a>
            <a routerLink="/contatos">Contactos</a>
        </div>

      </nav>

