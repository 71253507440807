import { Component, OnInit } from '@angular/core';

import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss']
})
export class FacebookComponent implements OnInit {



  constructor() {


   }




  ngOnInit(): void {
  }

}
